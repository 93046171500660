import request from '@/utils/request'

const url = '/auth-service/auths/login'

export function login(params) {
  return request({
    url: `${url}`,
    method: 'post',
    params: params
  })
}

export function loginByFrom(params) {
  return request({
    url: `${url}/WRITER_WEB`,
    method: 'post',
    params: params
  })
}

export function getVerifycode(params) {
  return request({
    url: `/auth-service/verifycodes/get_verifycode`,
    method: 'get',
    params: params
  })
}

export function loginByWxCode(params) {
  return request({
    url: `/auth-service/wx/oauth2code/${params.appid}`,
    method: 'get',
    params: params
  })
}

export function getTemporaryQrcode(params) {
  return request({
    url: `/auth-service/weixin/${params.appid}/temporary_qrcode`,
    method: 'get',
    params: params
  })
}
