<template>

    <!-- 登录/注册 弹窗 -->
    <el-dialog
      class="custom-dialog"
      ref="dialog-login"
      :visible.sync="showLoginDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="close"
      >
          <div v-if="ticket != ''">
            <img style="width: 100%; height: 100%;" :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+ ticket" alt="二维码" />
          </div>
          <!-- 用于展示二维码的容器 -->
          <!-- <div id="wxLoginContainer"></div>
          <div v-if="loading">正在验证登录...</div> -->

          <div style="font-size: 1.5rem; font-weight: 400;"><span>登录到</span><span class="pointer" @click="getTemporaryQrcode">扫码登录</span></div>
          <div style="font-weight: 600;color: #366EF4; font-size: 1.5rem; margin-bottom: 1rem;">MaliMali 码哩写作</div>
          <p style="color: rgba(41,40,43,0.6);font-size: 14px;margin: 0.3rem 0">你的创作金手指！</p>
          <el-form :model="form" ref="formRef" :rules="rules" class="login-form" label-position="left" :hide-required-asterisk='true'>

              <div class="d-flex flex-between">
                <el-form-item prop="countryCode" class="mb" style="width: 6rem;">
                  <el-select
                      v-model="form.countryCode"
                      @change="changeCountryCode"
                      clearable
                      filterable
                      size="small"
                      placeholder="国家码">
                    <el-option
                      v-for="(item,index) in countryCodes"
                      :key="index"
                      :label="item.country"
                      :value="item.countryCode"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item prop="username" class="mb" style="flex: 1;">
                  <el-input v-model="form.username" prefix-icon="el-icon-phone" placeholder="请输入手机号" autocomplete="on" class="custom-input-text" clearable />
                </el-form-item>
              </div>
            <el-form-item prop="verifyCode" class="mb">
              <el-input v-model="form.verifyCode" ref="verificationCodeInput" placeholder="请输入验证码" class="custom-input-text" clearable>
                <template slot="append">
                    <el-button @click="startCountdown" :loading="isGetCodeLoading" :disabled="getCaptchaDisabled" class="yanzhengma">
                      {{ getCaptchaDisabled ? `${timeLeft}秒后重发` : '获取验证码' }}
                    </el-button>
                  </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="checked" class="mb">
              <el-checkbox v-model="form.checked" style="color: rgba(41,40,43,0.6);">已阅读并同意《<a href="user_agreement.html" target="_blank" style="color: #366EF4;">用户协议</a>》和《<a href="private_policy.html" target="_blank" style="color: #366EF4;">隐私政策</a>》</el-checkbox>
              <el-checkbox v-if="countryCode1.startsWith('+1')" style="color: rgba(41,40,43,0.6);">我同意接收短信</el-checkbox>
            </el-form-item>
            <el-button style="border-radius: 6px !important;" class="mt w-full" type="primary" @click="handleLogin()" @keydown.native="handleKeyPress()">注册/登录</el-button>
          </el-form>
    </el-dialog>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import * as LoginApi from '@/api/auth'
import * as AccountApi from '@/api/account'
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      loading: false,
      ticket: '',
      appid: 'wxe31dc58f2bffc1ac', // 替换为你的appid
      redirectUri: encodeURIComponent('http://45e2d0d0.r32.cpolar.top'), // 需要和微信开放平台配置一致
      state: Math.random().toString(36).substring(2), // 随机状态码防止CSRF

      form: {
        applicationId: 0,
        username: '',
        phone: '',
        verifyCode: '',
        checked: false,
        countryCode: '+86'
      },
      countryCode1: '+86',
      countryCodes: [
        {"country": "中国", "countryCode": "+86"},
        {"country": "中国香港", "countryCode": "+852"},
        {"country": "中国澳门", "countryCode": "+853"},
        {"country": "中国台湾", "countryCode": "+886"},
        {"country": "美国", "countryCode": "+1"},
        {"country": "俄罗斯", "countryCode": "+7"},
        {"country": "英国", "countryCode": "+44"},
        {"country": "法国", "countryCode": "+33"},
        {"country": "德国", "countryCode": "+49"},
        {"country": "日本", "countryCode": "+81"},
        {"country": "韩国", "countryCode": "+82"},
        {"country": "意大利", "countryCode": "+39"},
        {"country": "加拿大", "countryCode": "+1 "},
        {"country": "澳大利亚", "countryCode": "+61"},
        {"country": "印度", "countryCode": "+91"},
        {"country": "巴西", "countryCode": "+55"},
        {"country": "西班牙", "countryCode": "+34"},
        {"country": "墨西哥", "countryCode": "+52"},
        {"country": "荷兰", "countryCode": "+31"},
        {"country": "瑞士", "countryCode": "+41"},
        {"country": "瑞典", "countryCode": "+46"},
        {"country": "比利时", "countryCode": "+32"},
        {"country": "奥地利", "countryCode": "+43"},
        {"country": "葡萄牙", "countryCode": "+351"},
        {"country": "爱尔兰", "countryCode": "+353"},
        {"country": "挪威", "countryCode": "+47"},
        {"country": "芬兰", "countryCode": "+358"},
        {"country": "丹麦", "countryCode": "+45"},
        {"country": "波兰", "countryCode": "+48"},
        {"country": "土耳其", "countryCode": "+90"},
        {"country": "希腊", "countryCode": "+30"},
        {"country": "南非", "countryCode": "+27"},
        {"country": "以色列", "countryCode": "+972"},
        {"country": "阿联酋", "countryCode": "+971"},
        {"country": "沙特阿拉伯", "countryCode": "+966"},
        {"country": "卡塔尔", "countryCode": "+974"},
        {"country": "科威特", "countryCode": "+965"},
        {"country": "埃及", "countryCode": "+20"},
        {"country": "阿根廷", "countryCode": "+54"},
        {"country": "哥伦比亚", "countryCode": "+57"},
        {"country": "智利", "countryCode": "+56"},
        {"country": "马来西亚", "countryCode": "+60"},
        {"country": "泰国", "countryCode": "+66"},
        {"country": "新加坡", "countryCode": "+65"},
        {"country": "印度尼西亚", "countryCode": "+62"},
        {"country": "菲律宾", "countryCode": "+63"},
        {"country": "新西兰", "countryCode": "+64"},
        {"country": "乌克兰", "countryCode": "+380"},
        {"country": "匈牙利", "countryCode": "+36"}
      ],
      rules: {
        username: [
          {validator: this.customValidateUsername, trigger: ['blur','change']}
        ],
        countryCode: [
          { required: true, message: '请选择国家码', trigger: ['blur','change'] }
        ],
        checked: [
          {validator: this.customValidatePolicy, trigger: ['blur','change']}
          // { required: true, message: '请阅读并同意以上协议', trigger: ['blur','change'] }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: ['blur','change'] },
          { min: 6, max: 6, message: '验证码长度必须为6位', trigger: ['blur','change'] }
        ]
      },
      isFormValid: true,
      isGetCodeLoading: false,
      getCaptchaDisabled: false,
      timeLeft: 60, // 初始倒计时时间
      intervalId: null, // 用于存储定时器的 ID
      account: {}
    }
  },
  computed: {
    ...mapState(['user', 'story', 'showLoginDialogFlag', 'urlquery']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '80%' : window.innerWidth <= 1200 ? '30%' : '30%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "20vh" : '10vh';
    },
    dialogTitle () {
      return this.story.id ? '故事设置' : '新建故事'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    document.addEventListener('keydown', this.handleKeyPress);
  },
  mounted() {
    // this.getTemporaryQrcode()
  },
  methods: {
    ...mapMutations(['setStory', 'setUser', 'setToken', 'setShowLoginDialogFlag', 'setShowVideoFlag', 'setIsAuthenticated']),
    close(){
      this.setShowLoginDialogFlag(false);
    },
    // 初始化微信二维码
       initWxLogin() {
         // 引入微信登录JS文件
         const script = document.createElement('script')
         script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
         script.onload = () => {
           new WxLogin({
             self_redirect: false,
             id: 'wxLoginContainer', // 二维码容器ID
             appid: this.appid,
             scope: 'snsapi_login',
             redirect_uri: this.redirectUri,
             state: this.state,
             style: '',
             href: '' // 可自定义样式
           })
         }
         document.body.appendChild(script)

         // 监听窗口消息
         window.addEventListener('message', this.handleWxLogin)
       },

       // 处理微信登录回调
       handleWxLogin(event) {
         // alert(JSON.stringify(event))
         if (event.data && event.data.code) {
           this.loading = true
           // 获取到code后发送到后端换取用户信息
           this.getWxUserInfo(event.data.code)
         }
       },

       // 获取用户信息
       async getWxUserInfo(code) {
         try {
            // 调用后端API
            const res = await LoginApi.loginByWxCode({
             appid: this.appid,
             code: code,
             state: this.state
            })
            let result = res.data
            if (result.code === 0) {
              this.account = result.data
              console.log('wxLogin', this.account);
              localStorage.setItem('lyToken', this.account.token)
              localStorage.setItem('lyAccount', JSON.stringify(this.account))

              this.$store.commit('setToken', this.account.token)
              this.$store.commit('setUser', this.account)

              // 登录状态
              this.$store.commit('setIsAuthenticated', this.account.token != '')
              this.$router.push('/home')
              // 调用 this.handleNewerGift();
              this.$parent.handleNewerGift();
              this.close()
              this.form.username = ''
              this.form.phone = ''
              this.form.verifyCode = ''
              this.form.countryCode = ''
            }
         } catch (error) {
           console.error('微信登录失败:', error)
           this.loading = false
         }
       },

       async getTemporaryQrcode() {
         try {
            // 调用后端API
            const res = await LoginApi.getTemporaryQrcode({ appid: this.appid })
            let result = res.data
            if (result.code === 0) {
              this.account = result.data
              this.ticket = result.data.ticket

              console.log('wxLogin', this.account);
              return false;
              localStorage.setItem('lyToken', this.account.token)
              localStorage.setItem('lyAccount', JSON.stringify(this.account))

              this.$store.commit('setToken', this.account.token)
              this.$store.commit('setUser', this.account)

              // 登录状态
              this.$store.commit('setIsAuthenticated', this.account.token != '')
              this.$router.push('/home')
              // 调用 this.handleNewerGift();
              this.$parent.handleNewerGift();
              this.close()
              this.form.username = ''
              this.form.phone = ''
              this.form.verifyCode = ''
              this.form.countryCode = ''
            }
         } catch (error) {
           console.error('微信登录失败:', error)
           this.loading = false
         }
       },
    changeCountryCode(val){
      console.log('val', val);
      this.countryCode1 = val
    },
    customValidateUsername(rule, value, callback) {
      // console.log('rule:', rule, 'value:', value);
      // 根据表单数据执行不同的验证
      if (this.form.username.length <= 0) {
        callback(new Error('请输入手机号！'));
      } else if (this.form.countryCode.startsWith('+86')) {
        const regex = /^1[3-9]\d{9}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+886')) {//台湾
        const regex = /^09\d{8}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+852')) {//HK
        const regex = /^[569]\d{7}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+853')) {//澳门
        const regex = /^6\d{7}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+1')) {//US
        const regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+44')) {//UK
        const regex = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:7(?:[45789]\d|624))|(?:1[2-9]\d{2}))\)?[\s-]?\d{3}[\s-]?\d{3,4}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+82')) {//kerea
        const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else if (this.form.countryCode.startsWith('+81')) {//japan
        const regex = /^(0[789]0)[0-9]{8}$|^(0[789]0)[0-9]{9}$/;
        if(!regex.test(value)){
          callback(new Error('手机号格式有误！'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    customValidatePolicy(rule, value, callback) {
      // console.log('rule:', rule, 'value:', value);
      // 根据表单数据执行不同的验证
      if (this.form.checked) {
        callback();
      } else {
        callback(new Error('请阅读并同意以上协议'));
      }
    },
    handleKeyPress(event) {
      // 检查按下的键是否是回车键
      if (event.key === 'Enter') {
        // 执行你想要的操作，例如提交表单
        this.handleLogin();
      }
    },
    debugLogin() {
      let token = 'eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MTg5Nzg5Mjk4ODAyNTU3NzQ3M30.tji7wwVLlixy6N028TuDRS21X9-S1blL-toJPBL07h0'
      localStorage.setItem('lyToken', token)
      AccountApi.view('1897892988025577473')
          .then(res => {
            // 登录成功
            if (res.data.code === 0) {
              this.account = res.data.data
              this.account.token = token
              console.log('debugLogin', this.account);
              localStorage.setItem('lyToken', this.account.token)
              localStorage.setItem('lyAccount', JSON.stringify(this.account))

              this.$store.commit('setToken', this.account.token)
              this.$store.commit('setUser', this.account)

              // 登录状态
              this.$store.commit('setIsAuthenticated', this.account.token != '')
              this.$router.push('/home')
              // 调用 this.handleNewerGift();
              this.$parent.handleNewerGift();
              this.close()
              this.form.username = ''
              this.form.phone = ''
              this.form.verifyCode = ''
              this.form.countryCode = ''
            }
          })
    },
    handleLogin() {
      // this.debugLogin();return;

      // let md5 = require('md5')
      let params = Object.assign({}, this.form)
      params.urlquery = localStorage.urlquery
      // params.verifyCode = md5(this.form.verifyCode)
      params.countryCode = this.form.countryCode.trim()
      this.$refs['formRef'].validate((valid) => {
        console.log('valid', valid);
        if (valid) {
          LoginApi.loginByFrom(params)
              .then(res => {
                // 登录成功
                if (res.data.code === 0) {
                  this.account = res.data.data
                  console.log('handleLogin', this.account);
                  localStorage.setItem('lyToken', this.account.token)
                  localStorage.setItem('lyAccount', JSON.stringify(this.account))

                  this.$store.commit('setToken', this.account.token)
                  this.$store.commit('setUser', this.account)

                  // 登录状态
                  this.$store.commit('setIsAuthenticated', this.account.token != '')
                  this.$router.push('/home')
                  // 调用 this.handleNewerGift();
                  this.$parent.handleNewerGift();
                  this.close()
                  this.form.username = ''
                  this.form.phone = ''
                  this.form.verifyCode = ''
                  this.form.countryCode = ''
                }
              })
        }else{
          console.log('表单验证失败');
        }
      });
    },
    startCountdown() {
      this.isGetCodeLoading = true
      this.isFormValid = true;
      if (this.intervalId) {
        clearInterval(this.intervalId); // 如果已经有一个定时器在运行，先清除
      }
      this.$refs.formRef.clearValidate('countryCode');
      this.$refs.formRef.validateField('countryCode', (message) => {
        console.log('countryCode', message);
        if (message) {
          this.isFormValid = false;
          this.isGetCodeLoading = false
        }
      });

      this.$refs.formRef.clearValidate('username');
      this.$refs.formRef.validateField('username', (message) => {
        if (message) {
          this.isFormValid = false;
          this.isGetCodeLoading = false
        }
      });

      setTimeout(()=>{
        if (this.isFormValid) {
          this.form.phone = this.form.username
          let param = Object.assign({}, this.form)
          param.countryCode = param.countryCode.trim()
          LoginApi.getVerifycode(param)
            .then(res => {
              // 获取成功
              if (res.data.code === 0) {
                this.getCaptchaDisabled = true;
                this.isGetCodeLoading = false
                this.intervalId = setInterval(() => {
                  if (this.timeLeft > 0) {
                    this.timeLeft--; // 每秒减少 1
                  } else {
                    clearInterval(this.intervalId); // 倒计时结束，清除定时器
                    this.timeLeft = 60; // 重置倒计时时间
                    this.getCaptchaDisabled = false;
                  }
                }, 1000);

                this.$nextTick(() => {
                  this.$refs.verificationCodeInput.focus();
                });
              }else{
                this.getCaptchaDisabled = false;
                this.isGetCodeLoading = false
              }
            })
        } else {
          console.log('手机号码验证失败');
          this.getCaptchaDisabled = false;
          this.isGetCodeLoading = false
          return false
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
    document.removeEventListener('keydown', this.handleKeyPress);
  },
}
</script>
<style>
.el-input--small .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
}
</style>
<style scoped lang="scss">
.login-form{
  color: #ffffff;
  padding: 1rem 0;
}
/deep/ .el-form-item__label-wrap .el-form-item__label {
  color: #ffffff !important;
}
.mt {
  margin-top: 1rem !important;
}
.mb {
  margin-bottom: 1rem !important;
}
/deep/  .yanzhengma.el-button {
  background-color: #ffffff; /* 设置背景颜色 */
  border: none; /* 设置边框宽度、样式和颜色 */
  box-shadow: none;
  cursor: pointer; /* 鼠标悬停时显示指针手势 */
  // border: 1px solid #5C5A5A;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
/deep/ .custom-input-text .el-button--default {
  background-color: #ffffff; /* 设置背景颜色 */
}
/deep/ .custom-dialog .el-dialog__wrapper {
  background-color: rgba(0, 0, 0, 0.01) !important;
}
</style>
